import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout";
const imageUltimateBLT = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-ultimate-blt-recipe-1367x737px.jpg';

const RecipePage = () => (
    <Layout metaTitle="Ultimate BLT Recipe"
        metaDescription="Discover how to make the Ultimate BLT
        sandwich. Our step-by-step guide includes
        our famous Wright® Brand Bacon,
        lettuce, and tomato to make a delicious
        sandwich."
        metaKeywords=""
    >
        <meta property="og:image" content={imageUltimateBLT} />
        <link rel="image_src" href={imageUltimateBLT} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageUltimateBLT} class='img-responsive' alt="The Wright Brand Klondike Brittle" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Ultimate BLT</h1>

                            <div class="recipe-content">
                                <p >This fully loaded BLT is sure to blow your taste buds away.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        45&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        20&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>1 </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 strips of Wright® Brand, Thick-Cut Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 thick sliced Texas toast
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                lettuce leaf
                </div>

                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 large ripe tomatoes 
                </div>

                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                jalapenos, sliced pickled
                </div>

                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 tablespoon Duke's® mayo
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/4 stick salted butter
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat convetional oven to 400F.

                </p>
                            <p>
                                2. Place the bacon on a foil lined sheet pan in a single layer, cook for 18 - 20 mins or until cooked to desired crispness.
                </p>
                            <p>
                                3. Remove bacon from oven & place on paper towels to drain excess grease. Set aside.
                </p>
                            <p>
                                4. Toast and butter 3 slices of bread on both sides.
                </p>
                           <p>
                                5. Cut tomato into thick slices, salt & pepper.
                </p>
                           <p>
                                6. Tear lettuce into large pieces.
                </p>
                           <p>
                                7. Spread Duke's® mayo on first piece of toasted bread, face up.
                </p>
                           <p>
                                8. Stack lettuce, tomato, bacon & pickled jalapenos.
                </p>
                           <p>
                                9. Top with additional slice of bread and repeat previous step.
                </p>
                <p>
                                10. Spread Duke's® mayo on last slice of bread and top sandwich. Cut in half & serve.

                </p>


                        </div>


                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">
                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>
            </div>
        </article>
    </Layout>
)

export default RecipePage
